import CFetch from "./fetch";
import { CredentialCreationOptions } from "@urbancarecommunity/commons/@types";
import { MFATypes } from "@urbancarecommunity/commons/@types";
import {
  PublicKeyCredentialWithAttestationJSON,
  PublicKeyCredentialWithAssertionJSON,
} from "@github/webauthn-json";
import { IUserInfo } from "@urbancarecommunity/commons/@types";
import { Tokens } from "@urbancarecommunity/commons/@types";
import { ICarerRegistrationDetails } from "@urbancarecommunity/commons/@types";
import { IClientRegistrationDetails } from "@urbancarecommunity/commons/@types";
import { authServiceInstance } from "@auth";
import { APIEndpoints } from "@urbancarecommunity/commons/constants";
import { IdentityEndpoints } from "@urbancarecommunity/commons/constants";

const endpoints = new APIEndpoints<typeof IdentityEndpoints>(
  IdentityEndpoints,
  import.meta.env.VUE_API_IDENTITY_URL as string
).get();

export const getUsers = (paginateOptions: any) => {
  return CFetch.callAPI<IUserInfo[]>(endpoints.users.build(), {
    params: paginateOptions,
  });
};

export const login = (email: string, password: string) => {
  return CFetch.callAPIWithoutAuth<Tokens>(endpoints.login.build(), {
    method: "post",
    body: { email, password },
  });
};

export const resetReq = (email: string) => {
  return CFetch.callAPIWithoutAuth<Tokens>(endpoints.resetReq.build(), {
    method: "post",
    body: { email },
  });
};

export const resetPass = (token: string, password: string) => {
  return CFetch.callAPIWithoutAuth<Tokens>(endpoints.reset.build(), {
    method: "post",
    body: { token, password },
  });
};

export const getToken = async (accessToken: string | null) => {
  const authHeaders = new Headers();
  if (accessToken) {
    authHeaders.set("Authorization", `Bearer ${accessToken}`);
  }

  return CFetch.callAPIWithoutAuth<Tokens>(endpoints.token.build(), {
    headers: authHeaders,
  });
};

export const getOTPSetupCode = () => {
  return CFetch.callAPI(endpoints.otpSetup.build());
};

export const verifyOTPSetup = (code: string) => {
  return CFetch.callAPI<Tokens>(endpoints.otpSetup.build(), {
    method: "POST",
    body: { code },
  });
};

export const verifyOTPCode = (code: string) => {
  return CFetch.callAPI<Tokens>(endpoints.otpVerify.build(), {
    method: "POST",
    body: { code },
  });
};

export const getFidoCredentialCreationOptions = async () => {
  return CFetch.callAPI<CredentialCreationOptions>(
    endpoints.fidoCredentialCreationOptions.build()
  );
};

export const registerFidoCredential = async (
  credential: PublicKeyCredentialWithAttestationJSON
) => {
  return CFetch.callAPI<Tokens>(endpoints.fidoRegisterCredential.build(), {
    method: "POST",
    body: credential,
  });
};

export const getFidoAuthOptions = async () => {
  return CFetch.callAPI<CredentialCreationOptions>(
    endpoints.fidoAuthOptions.build()
  );
};

export const getEnabledMFAs = async () => {
  return CFetch.callAPI<MFATypes[]>(endpoints.enabledMFAs.build());
};

export const verifyFidoCredential = async (
  credential: PublicKeyCredentialWithAssertionJSON
) => {
  return CFetch.callAPI<Tokens>(endpoints.fidoverifyCredential.build(), {
    method: "POST",
    body: credential,
  });
};

export const getRichAuthToken = async (
  credential: PublicKeyCredentialWithAssertionJSON,
  hash: string
) => {
  return CFetch.callAPI<{ richAuthToken: string }>(
    endpoints.fidoGetRichAuthToken.build(),
    {
      method: "POST",
      body: {
        credential,
        hash,
      },
    }
  );
};

export const getUserInfo = async () => {
  return CFetch.callAPI<IUserInfo>(endpoints.userInfo.build());
};

export const logout = async () => {
  return CFetch.callAPIWithoutAuth(endpoints.logout.build());
};

export const registerCareWorker = async (
  details: ICarerRegistrationDetails
) => {
  const { data, error } = await CFetch.callAPIWithoutAuth<Tokens>(
    endpoints.registerCarer.build(),
    {
      method: "POST",
      body: details,
    }
  );

  if (error) {
    return error;
  }

  authServiceInstance.setTokens(data);
};

export const registerClient = async (details: IClientRegistrationDetails) => {
  const { data, error } = await CFetch.callAPIWithoutAuth<Tokens>(
    endpoints.registerClient.build(),
    {
      method: "POST",
      body: details,
    }
  );

  if (error) {
    return error;
  }

  authServiceInstance.setTokens(data);
};

export const getLoginToken = async (userId: string) => {
  return CFetch.callAPIWithRichAuth<{ loginToken: string }>(
    endpoints.loginToken.buildWithParams({ userId }),
    { hash: userId }
  );
};

export const loginWithToken = async (loginToken: string) => {
  const { data, error } = await CFetch.callAPIWithoutAuth<Tokens>(
    endpoints.loginWithToken.build(),
    {
      method: "POST",
      body: { loginToken },
    }
  );

  if (error) {
    return error;
  }

  authServiceInstance.setTokens(data);
};

export const getIsPinExists = async (userId: string) => {
  return CFetch.callAPI(endpoints.authPin.buildWithParams({ id: userId }));
};

export const setAuthPin = (
  userId: string,
  pin: {
    newPin: number;
  }
) =>
  CFetch.callAPI(
    endpoints.authPin.buildWithParams({
      id: userId,
    }),
    {
      method: "PUT",
      body: pin,
    }
  );

export const updateAuthPin = (
  userId: string,
  pinDetails: {
    newPin: string;
    password: boolean;
  }
) =>
  CFetch.callAPI(
    endpoints.authPin.buildWithParams({
      id: userId,
    }),
    {
      method: "POST",
      body: pinDetails,
    }
  );
