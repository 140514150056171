import { IUserType } from "@urbancarecommunity/commons/@types";
import { ClientRole } from "@urbancarecommunity/commons/constants";
import { NavigationGuard } from "vue-router";
import { authServiceInstance } from "..";

export const ClientSubmittedGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isAuthenticatedAsClient()) {
    return next("/auth/login");
  }

  const role = authServiceInstance.getAuthRole();
  if (
    role === ClientRole.ApprovedIndividual ||
    role === ClientRole.ApprovedOrganisation
  ) {
    return next("/client/portal");
  }
  if (role !== ClientRole.UnapprovedClient) {
    return next("/");
  }

  next();
};

export const ClientGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isAuthenticatedAsClient()) {
    return next("/auth/login");
  }

  const user = authServiceInstance.getAuthenticatedUser();
  if (user?.userType !== IUserType.CLIENT) {
    return next("/auth/login");
  }

  next();
};

export const ClientPortalGuard: NavigationGuard = (to, from, next) => {
  if (!authServiceInstance.isAuthenticatedAsClient()) {
    return next("/auth/login");
  }

  const role = authServiceInstance.getAuthRole();
  if (role === ClientRole.UnapprovedClient) {
    return next("/client/submitted");
  }
  if (
    role !== ClientRole.ApprovedIndividual &&
    role !== ClientRole.ApprovedOrganisation
  ) {
    return next("/carer");
  }

  next();
};
