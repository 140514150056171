import mongoose from "mongoose";
import { IDineroSchema } from "../dinero";
import { ICarers } from "./jobPostShift";
import { ICarerDetails } from "../carer/details";

export interface IBookingCostBreakdown {
  cost: IDineroSchema;
  allShiftsCost: IDineroSchema;
  uccAmount: IDineroSchema;
  stripeFee: IDineroSchema;
}

export interface ICarerShifts {
  _id: mongoose.Types.ObjectId;
  carer: mongoose.Types.ObjectId;
  shift: mongoose.Types.ObjectId;
}

export interface ICompletedClockingByCarers {
  shift: mongoose.Types.ObjectId;
  carer: mongoose.Types.ObjectId;
}
export interface ICancelledBy {
  userId: mongoose.Types.ObjectId;
  userType: string;
}

export type Coords = {
  latitude: number;
  longitude: number;
  accuracy: number;
};

export interface ClockInDetails {
  time: string;
  clientCoordinates: Coords;
  carerCoordinates: Coords;
}

export interface ClockOutDetails {
  time: string;
  clientCoordinates: Coords;
  carerCoordinates: Coords;
}

export interface BookingFormCareWorker {
  id: string;
  shift: string;
  role: string;
}

export interface BookingFormShift {
  carers: ICarers[];
  _id: string;
  name: string;
  startTime?: string;
  endTime?: string;
  address?: { _id: string };
  taskList?: string;
  requirements?: any;
}

export interface BookingForm {
  dates: string[];
  serviceUser: string | null;
  shifts: BookingFormShift[];
  currentShift: string | null;
  offSet: string | null;
}

export enum BookingStatus {
  requestOnProgress = "requestOnProgress",
  shiftWaiting = "shiftWaiting",
  rejected = "rejected",
  cancelled = "cancelled",
  expired = "expired",
  allShiftCancelled = "allShiftCancelled",
  paymentPending = "paymentPending",
  paymentCompleted = "paymentCompleted",
  clockInPending = "clockInPending",
  clockOutPending = "clockOutPending",
  waitingForReview = "waitingForReview",
  completed = "completed",
}

export interface IShiftCarer
  extends Pick<
    ICarerDetails,
    "_id" | "firstName" | "lastName" | "city" | "avatar"
  > {
  roleId: mongoose.Types.ObjectId;

  normalHoursCost: number;
  nightHoursCost: number;
  weekendNormalHoursCost: number;
  weekendNightHoursCost: number;

  accepted: boolean;
  rejected: boolean;

  clockInDetails: {
    time: Date;
    clientCoordinates: {
      latitude: number;
      longitude: number;
      accuracy: number;
    };
    carerCoordinates: {
      latitude: number;
      longitude: number;
      accuracy: number;
    };
  };
  clockOutDetails: {
    time: Date;
    clientCoordinates: {
      latitude: number;
      longitude: number;
      accuracy: number;
    };
    carerCoordinates: {
      latitude: number;
      longitude: number;
      accuracy: number;
    };
  };

  status?: BookingStatus;
}

export interface IBookingShift {
  _id: mongoose.Types.ObjectId;
  name: string;
  startTime: Date;
  endTime: Date;
  address: mongoose.Types.ObjectId;
  client: mongoose.Types.ObjectId;
  serviceUser: mongoose.Types.ObjectId;
  carers: IShiftCarer[];

  status: BookingStatus;

  normalHours: number;
  nightHours: number;
  weekendNormalHours: number;
  weekendNightHours: number;

  tasks: {
    _id: mongoose.Types.ObjectId;
    name: string;
    description: string;
    mandatory: boolean;
    carers: mongoose.Types.ObjectId[];
    serviceUsers: mongoose.Types.ObjectId[];
  }[];
  clockInDetails: {
    carer: mongoose.Types.ObjectId;
    time: Date;
    coordinates: {
      latitude: number;
      longitude: number;
      accuracy: number;
    };
  }[];
  clockOutDetails: {
    carer: mongoose.Types.ObjectId;
    time: Date;
    coordinates: {
      latitude: number;
      longitude: number;
      accuracy: number;
    };
  }[];
  completed: boolean;

  // acceptedBy: mongoose.Types.ObjectId[];
  // rejectedBy: mongoose.Types.ObjectId[];

  cancelled?: boolean;
  cancelledBy?: {
    userId: mongoose.Types.ObjectId;
    userType: "SERVICE_USER" | "CARER";
  };
  cancelledAt?: Date;
  reasonForCancellation?: string;

  reviews: mongoose.Types.ObjectId[];
  requirements?: [string];
}

export interface IBooking {
  referenceNumber: string;
  bookingForm: BookingForm;
  client: mongoose.Types.ObjectId;
  jobPost?: mongoose.Types.ObjectId;
  serviceUser: mongoose.Types.ObjectId;
  shifts: IBookingShift[];
  paid: boolean;
  paymentIntentId: string;
  stripeReference?: string;
  paidInCash: boolean;
  completed: boolean;

  startTime: Date;
  endTime: Date;

  status: BookingStatus;
  reviews: mongoose.Types.ObjectId[];

  cancelled?: boolean;
  cancelledBy?: mongoose.Types.ObjectId;
  cancelledAt?: Date;
  reasonForCancellation?: string;
  cost: IBookingCostBreakdown;
}

export interface IBookingSchema extends IBooking, mongoose.Document {
  createdAt: Date;
  updatedAt: Date;
}
