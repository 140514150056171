import moment from "moment";

export const getTaskLabelForSummary = (shift) => {
  const { tasks } = shift;

  return tasks?.length > 0 ? `${tasks.length} tasks added` : "No tasks added";
};

export const getShiftTime = (date) => {
  return moment(date).format("HH:mm");
};

export const getShiftDate = (date) => {
  return moment(date).format("Do MMM YYYY");
};

export const getShiftDuration = (shift) => {
  if (!shift?.startTime || !shift?.endTime) {
    return "";
  }

  let startTime = moment(shift.startTime);
  let endTime = moment(shift.endTime);

  if (moment(shift.startTime, "HH:mm", true).isValid()) {
    startTime = moment(shift.startTime, "HH:mm");
  }
  if (moment(shift.endTime, "HH:mm", true).isValid()) {
    endTime = moment(shift.endTime, "HH:mm");
  }

  if (endTime.isSameOrBefore(startTime)) {
    endTime = endTime.add(1, "day");
  }

  const diff = endTime.diff(startTime);

  const duration = moment.duration(diff);

  const durationAsMinutes = duration.asMinutes();

  if (durationAsMinutes < 60) {
    return `${durationAsMinutes} minutes`;
  }
  return `
        ${duration.hours() || 24} hours
        ${duration.minutes() > 0 ? `${duration.minutes()} minutes` : ""}
      `;
};
