export enum AdminRole {
  SuperAdmin = "admin:super",
  Maintainer = "admin:maintainer",
  Viewer = "admin:viewer",
}

export enum CommonRole {
  MfaSetup = "user:mfa_setup",
  MfaRequired = "user:mfa_required",
  PasswordReset = "user:password_reset",
}

export enum ClientRole {
  DisabledClient = "client:disabled",
  ApprovedIndividual = "client:individual",
  ApprovedOrganisation = "client:organisation",
  UnapprovedClient = "client:unapproved",
}

export enum CarerRole {
  DisabledCarer = "carer:disabled",
  ApprovedCarer = "carer:approved",
  SubmittedCarer = "carer:submitted",
  UnapprovedCarer = "carer:unapproved",
}

export type Role = CommonRole | ClientRole | CarerRole | AdminRole;
